<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
    size="sm"
  >
    <template #header>
      Poziomy prowizyjne
    </template>

    <template #default>
      <div class="flex items-center">
        <div class="flex-grow font-semibold">nazwa</div>
        <div class="w-20 sm:w-24 text-center font-semibold text-sm">prow. A</div>
        <div class="w-20 sm:w-24 text-center font-semibold text-sm">prow. B</div>
      </div>



      <div class="flex flex-col border dark:border-gray-600 rounded-sm divide-y dark:divide-gray-600 text-sm sm:text-base">
        <div v-for="(provision) in prvLvls" :key="provision.id" class="flex flex-nowrap divide-x dark:divide-gray-600">

          <div class="flex-grow">
            <div v-if="editedName === provision.id" class="flex flex-col">
              <input @keydown.enter="save(provision.id, 'name')" @keydown.esc="cancel" @blur="cancel" type="text" class="w-full" v-model="provisionLvlCache.name" :ref="`name${provision.id}`">
              <p v-if="saveErrors.name != undefined" class="text-red-700 text-sm">{{ saveErrors.name }}</p>
            </div>
            <a v-else href="#" @click.prevent="edit(provision.id, 'name')" class="block tw--link px-1">{{ provision.name }}</a>
          </div>
          <div class="w-20 sm:w-24">
            <div v-if="editedByAgent === provision.id" class="flex flex-col">
              <div class="w-full">
                <input @keydown.enter="save(provision.id, 'by_agent')" @keydown.esc="cancel" @blur="cancel" type="text" class="w-full" v-model="provisionLvlCache.by_agent" :ref="`by_agent${provision.id}`">
              </div>
              <p v-if="saveErrors.by_agent != undefined" class="text-red-700 text-sm">{{ saveErrors.by_agent }}</p>
            </div>
            <a v-else href="#" @click.prevent="edit(provision.id, 'by_agent')" class="block text-right tw--link px-1">{{ provision.by_agent | currencyNullAllow }}%</a>
          </div>
          <div class="w-20 sm:w-24">
            <div v-if="editedByOffice === provision.id" class="flex flex-col">
              <div class="w-full">
                <input @keydown.enter="save(provision.id, 'by_office')" @keydown.esc="cancel" @blur="cancel" type="text" class="w-full" v-model="provisionLvlCache.by_office" :ref="`by_office${provision.id}`">
              </div>
              <p v-if="saveErrors.by_office != undefined" class="text-red-700 text-sm">{{ saveErrors.by_office }}</p>
            </div>
            <a v-else href="#" @click.prevent="edit(provision.id, 'by_office')" class="block text-right tw--link px-1">{{ provision.by_office | currencyNullAllow }}%</a>
          </div>
        </div>
      </div>

      <ul class="list-outside list-disc ml-4 mt-1 w-full text-muted text-xs italic">
        <li>kliknij w nazwę lub stawkę, aby edytować</li>
        <li>prow. A: samodzielna (oferta przygotowana przez agenta)</li>
        <li>prow. B: oferta przygotowana przez biuro</li>
      </ul>

    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import ProductService from '../../services/ProductService';

export default {
  name: 'EditProvLvlsPS2',
  mixins: [errorHandler],
  data() {
    return {
      provisionLvlCache: {
        id: '',
        name: '',
        by_agent: '',
        by_office: '',
      },
      editedName: '',
      editedByAgent: '',
      editedByOffice: '',
      prvLvls: [],
      saveErrors: {},
      saving: false,
    };
  },
  created() {
    this.getProvLevlPS2();
  },
  methods: {
    getProvLevlPS2() {
      ProductService.getProvLevlPS2()
        .then(response => {
          this.prvLvls = response.data.result;
        })
        .catch(error => this.resolveError(error));
    },
    edit(id, field) {
      let fields = 0;
      const ref = `${field}${id}`;
      switch (field) {
        case 'name':
          this.editedName = id;
          fields += 1;
          break;
        case 'by_agent':
          this.editedByAgent = id;
          fields += 1;
          break;
        case 'by_office':
          this.editedByOffice = id;
          fields += 1;
          break;
        default:
          break;
      }
      if (fields !== 1) {
        return false;
      }
      const provision = this.prvLvls.find(obj => obj.id === id);
      Object.assign(this.provisionLvlCache, provision);
      this.$nextTick(() => {
        this.$refs[ref][0].select();
        this.$refs[ref][0].focus();
      });
      return true;
    },
    save(id, field) {
      ProductService.updateProvisionLevel(id, { [field]: this.provisionLvlCache[field] })
        .then(response => {
          const updatedProvisionLevel = this.prvLvls.find(obj => obj.id === id);
          updatedProvisionLevel[field] = response.data.updated_prov_lvl[field];
          this.$store.dispatch('notifications/add', { message: 'Zmiany zapisane', type: 'success' });
          this.cancel();
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
    cancel() {
      this.editedName = '';
      this.editedByAgent = '';
      this.editedByOffice = '';
      this.provisionLvlCache = {};
      this.saveErrors = {};
    },
  },
};
</script>
