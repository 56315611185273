<template>
  <BaseModal
    @closeModal="$emit('closeModal')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Edycja produktu
    </template>

    <template #default>
      <form @submit.prevent="save" @keydown.enter.prevent class="flex flex-wrap gap-y-1">
        <div class="w-full md:w-1/2 px-1">
          <BaseSelectWithAddon
            class="w-full"
            v-model="productCache.category_id"
            labelTop="kategoria"
            :options="productCategoryList"
            :nullOption="false"
            :error="saveErrors.category_id !== undefined ? saveErrors.category_id : ''"
          />
        </div>

        <div class="w-full md:w-1/2 px-1">
          <BaseSelectWithAddon
            class="w-full"
            v-model="productCache.insurer_id"
            labelTop="TU"
            :options="insurerList"
            :nullOption="false"
            :error="saveErrors.insurer_id !== undefined ? saveErrors.insurer_id : ''"
          />
        </div>

        <div class="w-full flex-grow-0 sm:w-auto sm:flex-grow px-1">
          <BaseInputWithAddon
            class="w-full"
            type="text"
            v-model="productCache.name"
            labelTop="nazwa"
            :error="saveErrors.name !== undefined ? saveErrors.name : ''"
          />
        </div>

        <div class="w-full sm:w-1/6 px-1">
          <BaseSelectWithAddon
            class="w-full"
            v-model="productCache.disabled"
            labelTop="aktywny"
            :options="[{id: '0', name: 'tak'}, {id: '1', name: 'nie'}]"
            :nullOption="false"
            :error="saveErrors.disabled !== undefined ? saveErrors.disabled : ''"
          />
        </div>

        <div v-if="products.length > 1" class="w-full flex flex-col text-sm">
          <div class="text-muted">Duplikaty produktu <em>(data obowiązywania od)</em></div>
          <div class="flex flex-wrap">
            <button v-for="(product, index) in products" :key="product.id"
              @click="cheangeProductVersion(product.id)"
              class="tw--btn rounded-none"
              :class="{'tw--btn-secondary': currentProduct !== index}"
              :disabled="currentProduct === index"
            >
              {{ index === 0 ? 'od początku' : product.date_from_h }}
            </button>
          </div>
        </div>

        <div v-if="productCache.date_from_h !== ''" class="w-full flex flex-col">
          <BaseInputWithAddon
            class="w-full sm:w-1/2"
            type="date"
            v-model="productCache.date_from_h"
            labelTop="data obowiązywania stawek"
            :error="saveErrors.date_from_h !== undefined ? saveErrors.date_from_h : ''"
             :disabled="productCache.date_from_h === ''"
          />
          <div class="text-xs text-bright">* pierwszy dzień m-ca</div>
        </div>

        <div v-if="Number(progOptions.provision_system) === 2" class="w-full sm:w-1/2 flex gap-x-1">
          <BaseInputWithAddon
            class="w-full text-right"
            type="text"
            v-model="productCache.income"
            labelTop="prowizja z TU"
            :error="saveErrors.income !== undefined ? saveErrors.income : ''"
          />
          <span>%</span>
        </div>

        <div v-if="Number(progOptions.provision_system) === 1" class="w-full flex flex-col gap-y-1">
          <div class="border-b dark:border-gray-600 uppercase text-muted">poziomy prowizyjne</div>
          <div class="text-xs text-muted">* TU: prowizja należna z TU, A: prowizja samodzielna (oferta przygotowana przez agenta), B: oferta przygotowana przez biuro</div>
          <div class="border dark:border-gray-600 px-2 py-1 rounded shadow flex flex-wrap" v-for="(level, index) in provisionLevels" :key="index">
            <div class="w-full sm:w-1/3 text-muted">{{ level.name }}</div>
            <div class="w-full sm:w-1/3 flex gap-x-1">
              <BaseInputWithAddon
                class="w-full text-right"
                type="text"
                v-model="productCache[provisionFormNames[index].a]"
                :labelAddon="index === 0 ? 'TU' : 'A'"
                :error="saveErrors[provisionFormNames[index].a] !== undefined ? saveErrors[provisionFormNames[index].a] : ''"
              />
              <span>%</span>
            </div>
            <div v-if="provisionFormNames[index].b !== ''" class="w-full sm:w-1/3 flex gap-x-1">
              <BaseInputWithAddon
                class="w-full text-right"
                type="text"
                v-model="productCache[provisionFormNames[index].b]"
                labelAddon="B"
                :error="saveErrors[provisionFormNames[index].b] !== undefined ? saveErrors[provisionFormNames[index].b] : ''"
              />
              <span>%</span>
            </div>
          </div>
        </div>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import moment from 'moment';
import ProductService from '../../services/ProductService';

export default {
  name: 'EditProductModal',
  mixins: [errorHandler, confirm],
  props: {
    productId: {
      type: String,
      required: true,
    },
    provisionLevels: {
      type: Array,
    },
  },
  data() {
    return {
      products: [],
      currentProduct: 0,
      productCache: {
        category_id: '',
        category_name: '',
        date_from: '',
        date_from_h: '',
        date_to: '',
        date_to_h: '',
        disabled: '',
        id: '',
        income: '',
        insurer_id: '',
        insurer_name: '',
        name: '',
        product_id: '',
        prov_perc_or_value: '',
        provision_a: '',
        provision_a1: '',
        provision_a2: '',
        provision_a3: '',
        provision_a4: '',
        provision_b: '',
        provision_b1: '',
        provision_b2: '',
        provision_b3: '',
        provision_b4: '',
      },
      updatedProductId: undefined,
      saveErrors: {},
      saving: false,
      loading: false,
      changed: false,
      provisionFormNames: [
        { a: 'income', b: '' },
        { a: 'provision_a', b: 'provision_b' },
        { a: 'provision_a1', b: 'provision_b1' },
        { a: 'provision_a2', b: 'provision_b2' },
        { a: 'provision_a3', b: 'provision_b3' },
        { a: 'provision_a4', b: 'provision_b4' },
      ],
    };
  },
  computed: {
    ...mapGetters('products', [
      'insurerList',
      'productCategoryList',
    ]),
    ...mapGetters([
      'progOptions',
    ]),
  },
  created() {
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.productCategoryList.length === 0) this.$store.dispatch('products/getProductCategoryList').catch(error => this.resolveError(error));
    this.getAllRelatedProducts();
  },
  methods: {
    getAllRelatedProducts() {
      this.loading = true;
      ProductService.getAllRelatedProducts(this.productId)
        .then(response => {
          this.products = response.data.result;
          if (this.updatedProductId === undefined) {
            const today = moment().format('YYYY-MM-DD');
            this.currentProduct = this.products.findIndex(obj => (obj.date_from_h <= today || obj.date_from_h === '') && (obj.date_to_h >= today || obj.date_to_h === ''));
          } else {
            this.currentProduct = this.products.findIndex(obj => obj.id === this.updatedProductId);
            this.updatedProductId = undefined;
          }
          this.cacheProduct();
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.resolveError(error);
        });
    },
    cheangeProductVersion(productId) {
      if (this.changed) {
        this.confirm(() => this.cheangeProductVersionConfirmed(productId), 'Nie zapisałeś zmian - przechodząc do kolejnej wersji produktu utracisz niezapisane zmiany. Kontynuować?');
      } else {
        this.cheangeProductVersionConfirmed(productId);
      }
    },
    cheangeProductVersionConfirmed(productId) {
      this.currentProduct = this.products.findIndex(obj => obj.id === productId);
      this.cacheProduct();
    },
    cacheProduct() {
      Object.assign(this.productCache, this.products[this.currentProduct]);
      delete this.productCache.category_name;
      delete this.productCache.date_from;
      delete this.productCache.date_to;
      delete this.productCache.insurer_name;
      delete this.productCache.date_from;
      this.productCache.income = this.productCache.income.replace('.', ',');
      this.productCache.provision_a = this.productCache.provision_a.replace('.', ',');
      this.productCache.provision_b = this.productCache.provision_b.replace('.', ',');
      if (this.provisionLevels.length > 2) {
        this.productCache.provision_a1 = this.productCache.provision_a1.replace('.', ',');
        this.productCache.provision_b1 = this.productCache.provision_b1.replace('.', ',');
      } else {
        delete delete this.productCache.provision_a1;
        delete delete this.productCache.provision_b1;
      }
      if (this.provisionLevels.length > 3) {
        this.productCache.provision_a2 = this.productCache.provision_a2.replace('.', ',');
        this.productCache.provision_b2 = this.productCache.provision_b2.replace('.', ',');
      } else {
        delete delete this.productCache.provision_a2;
        delete delete this.productCache.provision_b2;
      }
      if (this.provisionLevels.length > 4) {
        this.productCache.provision_a3 = this.productCache.provision_a3.replace('.', ',');
        this.productCache.provision_b3 = this.productCache.provision_b3.replace('.', ',');
      } else {
        delete delete this.productCache.provision_a3;
        delete delete this.productCache.provision_b3;
      }
      if (this.provisionLevels.length > 5) {
        this.productCache.provision_a4 = this.productCache.provision_a4.replace('.', ',');
        this.productCache.provision_b4 = this.productCache.provision_b4.replace('.', ',');
      } else {
        delete delete this.productCache.provision_a4;
        delete delete this.productCache.provision_b4;
      }
    },
    save() {
      this.saveErrors = {};
      this.saving = true;
      ProductService.updateProduct(this.productCache.id, this.productCache)
        .then(() => {
          this.saving = false;
          this.$emit('productUpdated');
          this.$store.dispatch('notifications/add', { message: 'Zmiany zostały zapisane w bazie.', type: 'success' });
          this.changed = false;
          this.updatedProductId = this.productCache.id;
          this.getAllRelatedProducts();
        })
        .catch((error) => {
          this.saving = false;
          this.saveErrors = this.resolveError(error);
        });
    },
  },
};
</script>
