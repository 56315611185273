<template>
  <div class="flex flex-col gap-y-2">
    <AddProductModal
      v-if="addProduct"
      :provisionLevels="provisionLevels"
      @closeModal="closeAddProductModal"
      @productAdded="productAdded"
    />
    <EditProductModal
      v-if="editProduct"
      :provisionLevels="provisionLevels"
      :productId="editedProduct.product_id"
      @closeModal="closeEditProductModal"
      @productUpdated="productUpdated"
    />
    <DuplicateProductModal
      v-if="duplicateProduct"
      :provisionLevels="provisionLevels"
      :product="duplicatedProduct"
      @closeModal="closeDuplicateProductModal"
      @productDuplicated="productDuplicated"
    />
    <EditProvLvlsPS2Modal
      v-if="EditProvLvlsPS2"
      @close='closeEditProvLvlsPS2Modal'
    />

    <div id="product-list-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="reminders-adv-filters-1" class="flex flex-col gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <!-- Search Button with addons -->
              <div class="flex flex-none items-center">
                <input
                  id="filterInput"
                  class="border-gray-300 dark:border-gray-600 rounded-r-none"
                  :class="{'bg-yellow-100 dark:bg-yellow-800': activeFilters.search.length > 0}"
                  v-model="activeFilters.search"
                  type="text"
                  placeholder="wpisz aby wyszukać..."
                >
                <button
                  @click="activeFilters.search = ''"
                  class="tw--btn tw--btn-secondary rounded-none border-t border-b border-gray-300 dark:border-gray-600"
                  type="button"
                  :disabled="activeFilters.search === ''"
                  title="wyczyść"
                ><FontAwesomeIcon icon="times"/></button>
                <button
                  @click="setUrl"
                  class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
                  type="button"
                  :disabled="activeFilters.search === ''"
                >
                  Zatwierdź
                </button>
              </div>
              <!-- End Search Button -->

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="setUrl"
                v-model="activeFilters.insurer"
                label="TU"
                :options="insurerList"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="setUrl"
                v-model="activeFilters.category"
                label="Kategoria"
                :options="productCategoryList"
              />

              <label for="show-not-active-prod" title="pokaż również nieaktywne produkty">
                <input
                  id="show-not-active-prod"
                  type="checkbox"
                  v-model="showNotActiveProducts"
                > pokaż nieaktywne
              </label>

            </div>
            <div class="flex flex-wrap items-center gap-3">
              <div v-if="Number(progOptions.provision_system) === 1" class="flex flex-wrap gap-x-3 gap-y-1">
                <div class="flex gap-x-2"><div>Poziomy</div><div class="text-sm px-2 py-1 bg-green-700 rounded-sm">{{ numberOfProvLvls }}</div></div>
                <div v-for="level in provisionLevels"  :key="level.id">
                  <div v-if="editedPrvLvl[level.id] === true" class="flex flex-col">
                    <input v-if="editedPrvLvl[level.id] === true"
                      @keydown.enter="updatePrvLvlName(level.id)"
                      @keydown.esc="cancelEditPrvLvl"
                      @blur="cancelEditPrvLvl"
                      type="text"
                      v-model="provisionLvlCache.name"
                      :ref="`prvLvl${level.id}`"
                    >
                    <p v-if="saveErrors.name != undefined" class="text-sm text-red-700">{{ saveErrors.name }}</p>
                  </div>
                  <div v-else class="flex">
                    <button
                      @click="editProvLvlName(level.id)"
                      class="tw--btn"
                      :class="{'rounded-r-none': Number(level.id) === numberOfProvLvls && Number(level.id) > 2}"
                      title="Kliknij, aby zmienić nazwę"
                    >{{ level.name }}</button>
                    <button
                      v-if="Number(level.id) === numberOfProvLvls && Number(level.id) > 2"
                      @click="delProvLvl(level.id)"
                      class="tw--btn tw--btn-secondary rounded-l-none"
                      title="Usuń poziom prowizyjny"
                    >
                      <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                    </button>
                  </div>
                </div>

                <button v-if="Number(numberOfProvLvls) < 6" @click="addProvLvl" class="tw--btn">Dodaj poziom</button>

              </div>

              <button v-else @click="EditProvLvlsPS2 = true" class="tw--btn" title="Kliknij, aby edytować poziomy prowizyjne">Poziomy prowizyjne</button>

            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="initList" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>

      </div>

      <div class="flex flex-wrap justify-between gap-y-1">
        <button @click="addProduct = true" class="tw--btn">Dodaj produkt</button>

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">znaleziono: {{ count.filtered }}</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">z: {{ count.all }}</span>
        </div>
      </div>
    </div>

    <div id="product-list-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

      <div id="product-list-table" class="flex flex-col gap-2">
        <SortList v-if="sortedBy.field !== ''" @sort="sortItemsBy" :fields="sortFields" :selected="sortedBy" class="max-w-min" />
        <div class="text-xs text-muted italic -mt-1">
          * opis kolumn poziomów prowizyjnych (A/B) - <strong>A</strong> - to prowizja agenta, który samodzielnie przygotował ofertę,
          <strong>B</strong> - to prowizja dla agenta naliczona w przypadku, kiedy agencja przygotowała ofertę dla agenta (tzw. oferta biura)
        </div>


        <ProductListItem
          v-for="product in filteredItems"
          :product="product"
          :provisionLevels="provisionLevels"
          :levels="numberOfProvLvls"
          :provisionSystem="progOptions.provision_system"
          :key="product.id"
          @advancedEdit="advancedEdit"
          @duplicate="duplicate"
          @productUpdated="productUpdated"
        ></ProductListItem>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import ProductListItem from './ProductListItem.vue';
import AddProductModal from './AddProduct.vue';
import EditProductModal from './EditProduct.vue';
import DuplicateProductModal from './DuplicateProduct.vue';
import EditProvLvlsPS2Modal from './EditProvLvlsPS2.vue';
import ProductService from '../../services/ProductService';
import SortList from '../SortList.vue';

export default {
  name: 'ProductList',
  components: {
    ProductListItem,
    AddProductModal,
    EditProductModal,
    DuplicateProductModal,
    EditProvLvlsPS2Modal,
    SortList,
  },
  mixins: [confirm, errorHandler],
  data() {
    return {
      products: [],
      provisionLevels: [],
      provisionLvlCache: {
        name: '',
      },
      editedPrvLvl: {},
      editedProduct: {},
      duplicatedProduct: {},
      sortedBy: {
        field: '',
        dir: 1,
      },
      sortFields: [
        { id: 'product_id', name: 'id produktu' },
        { id: 'insurer_name', name: 'tow. ubezp.' },
        { id: 'category_name', name: 'kategoria'},
        { id: 'name', name: 'nazwa produktu' },
        { id: 'income', name: 'prowizja z TU' },
        { id: 'date_from', name: 'data od' },
        { id: 'date_to', name: 'data do' },
      ],
      activeFilters: {
        search: '',
        category: '2',
        insurer: '',
      },
      loading: false,
      editProvLvlsPS2: false,
      addProduct: false,
      editProduct: false,
      duplicateProduct: false,
      EditProvLvlsPS2: false,
      helpModal: false,
      showNotActiveProducts: false,
      saveErrors: {},
    };
  },
  computed: {
    ...mapGetters('products', [
      'insurerList',
      'productCategoryList',
    ]),
    ...mapGetters([
      'progOptions',
    ]),
    numberOfProvLvls() {
      let numberOfLevels;
      if (Number(this.progOptions.provision_system) === 1) {
        numberOfLevels = this.provisionLevels.length;
      } else if (Number(this.progOptions.provision_system) === 2) {
        numberOfLevels = 1;
      }
      return numberOfLevels;
    },
    filteredItems() {
      if (Object.keys(this.activeFilters).length > 0) {
        const checkFilters = (item) => {
          let isMatch = true;
          Object.keys(this.activeFilters).forEach((key) => {
            if (this.activeFilters[key] !== '') {
              if (key === 'search') {
                const search = this.activeFilters[key].toLowerCase();
                if (!item.name.toLowerCase().includes(search)) {
                  isMatch = false;
                }
              } else if (key === 'category' && this.activeFilters[key] !== item.category_id) {
                isMatch = false;
              } else if (key === 'insurer' && this.activeFilters[key] !== item.insurer_id) {
                isMatch = false;
              }
            }
          });
          if (!this.showNotActiveProducts && Number(item.disabled) === 1) {
            isMatch = false;
          }
          return isMatch;
        };
        return this.products.filter(checkFilters);
      }
      return this.products;
    },
    count() {
      return { filtered: this.filteredItems.length, all: this.products.length };
    },
    isErrorLevel() {
      return [
        this.products.find(obj => obj.income === '') !== undefined,
        this.products.find(obj => obj.provision_a === '' || obj.provision_b === '') !== undefined,
        this.products.find(obj => obj.provision_a1 === '' || obj.provision_b1 === '') !== undefined,
        this.products.find(obj => obj.provision_a2 === '' || obj.provision_b2 === '') !== undefined,
        this.products.find(obj => obj.provision_a3 === '' || obj.provision_b3 === '') !== undefined,
        this.products.find(obj => obj.provision_a4 === '' || obj.provision_b4 === '') !== undefined,
      ];
    },
  },
  created() {
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.productCategoryList.length === 0) this.$store.dispatch('products/getProductCategoryList').catch(error => this.resolveError(error));
    this.initList();
  },
  methods: {
    initList(refresh = true) {
      this.loading = true && refresh;
      ProductService.getProductList()
        .then(response => {
          this.products = response.data.result;
          this.provisionLevels = response.data.provisionLevels;
          if (this.$route.query.sf !== undefined && ['product_id', 'insurer_name', 'category_name', 'name', 'income', 'provision_a', 'provision_b', 'date_from', 'date_to'].includes(this.$route.query.sf)) {
            this.sortedBy.field = this.$route.query.sf;
            if (this.$route.query.sd !== undefined) {
              this.sortedBy.dir = Number(this.$route.query.sd) === -1 ? -1 : 1;
            } else {
              this.sortedBy.dir = 1;
            }
          } else {
            this.sortedBy.field = 'insurer_name';
            this.sortedBy.dir = 1;
          }
          if (this.$route.query.search !== undefined) {
            this.activeFilters.search = decodeURI(this.$route.query.search);
          }
          if (this.$route.query.category !== undefined) {
            this.activeFilters.category = decodeURI(this.$route.query.category);
          }
          if (this.$route.query.insurer !== undefined) {
            this.activeFilters.insurer = decodeURI(this.$route.query.insurer);
          }
          this.sortItemList();
          this.setUrl();
          this.loading = false;
        })
        .catch((error) => {
          if (error.response !== undefined) {
            console.error(error.response.data.result);
          } else {
            console.error(error);
          }
        });
    },
    sortItemsBy(object) {
      this.sortedBy.field = object.field;
      this.sortedBy.dir = object.dir;
      this.sortItemList();
      this.setUrl();
    },
    sortItemList() {
      const compareNumbers = (a, b) => this.sortedBy.dir * (a[this.sortedBy.field] - b[this.sortedBy.field]);
      const compareText = (a, b) => {
        const aName = a[this.sortedBy.field].toLowerCase();
        const bName = b[this.sortedBy.field].toLowerCase();
        let tmpDir = 0;
        if (aName < bName) {
          tmpDir = -1;
        } else if (aName > bName) {
          tmpDir = 1;
        }
        return this.sortedBy.dir * tmpDir;
      };
      if (['product_id', 'income', 'provision_a', 'provision_b'].includes(this.sortedBy.field)) {
        this.products.sort(compareNumbers);
      } else {
        this.products = this.products.sort(compareText);
      }
    },
    sortedIcon(field) {
      if (this.sortedBy.field === field) {
        return this.sortedBy.dir === 1 ? 'has-text-success fa-sort-amount-up' : 'has-text-success fa-sort-amount-down';
      }
      return 'fa-sort';
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setUrl();
    },
    productUpdated() {
      this.editProduct = false;
      this.initList(false);
    },
    productAdded(product) {
      this.addProduct = false;
      this.products.push(product);
      this.sortItemList();
    },
    productDuplicated() {
      this.duplicateProduct = false;
      this.initList();
    },
    advancedEdit(product) {
      this.editedProduct = product;
      this.editProduct = true;
    },
    duplicate(product) {
      this.duplicatedProduct = product;
      this.duplicateProduct = true;
    },
    editProvLvlName(id) {
      const level = this.provisionLevels.find(obj => obj.id === id);
      this.provisionLvlCache.name = level.name;
      this.editedPrvLvl = {};
      this.editedPrvLvl[id] = true;
      const ref = `prvLvl${id}`;
      this.$nextTick(() => {
        this.$refs[ref][0].select();
        this.$refs[ref][0].focus();
      });
    },
    updatePrvLvlName(id) {
      ProductService.updateProvisionLevelName(id, { name: this.provisionLvlCache.name })
        .then(() => {
          const updatedProvisionLevel = this.provisionLevels.find(obj => obj.id === id);
          updatedProvisionLevel.name = this.provisionLvlCache.name;
          this.$store.dispatch('employees/getEmplProvList');
          this.cancelEditPrvLvl();
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
    cancelEditPrvLvl() {
      Object.keys(this.editedPrvLvl).forEach((key) => { this.editedPrvLvl[key] = false; });
      this.provisionLvlCache = {};
    },
    addProvLvl() {
      this.loading = true;
      ProductService.addProvisionLevel()
        .then(() => {
          this.$store.dispatch('employees/getEmplProvList');
          this.initList();
          this.loading = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.loading = false;
        });
    },
    delProvLvl(id) {
      this.confirm(() => this.confirmedDelProvLvl(id), 'Czy potwierdzasz usunięcie poziomu prowizyjnego? Spowoduje to nieodwracalne usunięcie stawek w produktach z kolumn powiązanych z poziomem prowizyjnym.');
    },
    confirmedDelProvLvl(id) {
      this.loading = true;
      ProductService.delProvisionLevel(id)
        .then(() => {
          this.$store.dispatch('employees/getEmplProvList');
          this.initList();
          this.loading = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.loading = false;
        });
    },
    setUrl() {
      const query = {
        sf: this.sortedBy.field,
        sd: `${this.sortedBy.dir}`,
        search: encodeURI(this.activeFilters.search),
        category: encodeURI(this.activeFilters.category),
        insurer: encodeURI(this.activeFilters.insurer),
      };
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'ProductList', query });
      }
    },
    closeAddProductModal() {
      this.addProduct = false;
    },
    closeEditProductModal() {
      this.editProduct = false;
    },
    closeDuplicateProductModal() {
      this.duplicateProduct = false;
    },
    closeEditProvLvlsPS2Modal() {
      this.EditProvLvlsPS2 = false;
    },
  },
};
</script>
