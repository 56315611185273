<template>
  <BaseModal
    @closeModal="$emit('closeModal')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="saveNew"
  >
    <template #header>
      Nowy produkt
    </template>

    <template #default>
      <form @submit.prevent="saveNew" @keydown.enter.prevent class="flex flex-wrap gap-x-3 gap-y-1">
        <BaseSelectWithAddon
          class="w-full md:w-1/2"
          v-model="newProduct.category_id"
          labelTop="kategoria"
          :options="productCategoryList"
          :nullOption="false"
          :error="saveErrors.category_id !== undefined ? saveErrors.category_id : ''"
        />

        <BaseSelectWithAddon
          class="w-full md:w-1/2"
          v-model="newProduct.insurer_id"
          labelTop="TU"
          :options="insurerList"
          :nullOption="false"
          :error="saveErrors.insurer_id !== undefined ? saveErrors.insurer_id : ''"
        />

        <BaseInputWithAddon
          class="w-full"
          type="text"
          v-model="newProduct.name"
          labelTop="nazwa"
          :error="saveErrors.name !== undefined ? saveErrors.name : ''"
        />

        <div v-if="Number(progOptions.provision_system) === 2" class="w-full sm:w-1/2 flex gap-x-1">
          <BaseInputWithAddon
            class="w-full text-right"
            type="text"
            v-model="newProduct.income"
            labelTop="prowizja z TU"
            :error="saveErrors.income !== undefined ? saveErrors.income : ''"
          />
          <span>%</span>
        </div>

        <div v-if="Number(progOptions.provision_system) === 1" class="w-full flex flex-col gap-y-1">
          <div class="border-b dark:border-gray-600 uppercase text-muted">poziomy prowizyjne</div>
          <div class="text-xs text-muted">* TU: prowizja należna z TU, A: prowizja samodzielna (oferta przygotowana przez agenta), B: oferta przygotowana przez biuro</div>
          <div class="border dark:border-gray-600 px-2 py-1 rounded shadow flex flex-wrap" v-for="(level, index) in provisionLevels" :key="index">
            <div class="w-full sm:w-1/3 text-muted">{{ level.name }}</div>
            <div class="w-full sm:w-1/3 flex gap-x-1">
              <BaseInputWithAddon
                class="w-full text-right"
                type="text"
                v-model="newProduct[provisionFormNames[index].a]"
                :labelAddon="index === 0 ? 'TU' : 'A'"
                :error="saveErrors[provisionFormNames[index].a] !== undefined ? saveErrors[provisionFormNames[index].a] : ''"
              />
              <span>%</span>
            </div>
            <div v-if="provisionFormNames[index].b !== ''" class="w-full sm:w-1/3 flex gap-x-1">
              <BaseInputWithAddon
                class="w-full text-right"
                type="text"
                v-model="newProduct[provisionFormNames[index].b]"
                labelAddon="B"
                :error="saveErrors[provisionFormNames[index].b] !== undefined ? saveErrors[provisionFormNames[index].b] : ''"
              />
              <span>%</span>
            </div>
          </div>
        </div>


      </form>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import ProductService from '../../services/ProductService';

export default {
  name: 'AddProductModal',
  mixins: [errorHandler],
  props: {
    provisionLevels: {
      type: Array,
    },
  },
  data() {
    return {
      newProduct: {
        prov_perc_or_value: 1,
      },
      saveErrors: {},
      saving: false,
      provisionFormNames: [
        { a: 'income', b: '' },
        { a: 'provision_a', b: 'provision_b' },
        { a: 'provision_a1', b: 'provision_b1' },
        { a: 'provision_a2', b: 'provision_b2' },
        { a: 'provision_a3', b: 'provision_b3' },
        { a: 'provision_a4', b: 'provision_b4' },
      ],
    };
  },
  computed: {
    ...mapGetters('products', [
      'insurerList',
      'productCategoryList',
    ]),
    ...mapGetters([
      'progOptions',
    ]),
  },
  mounted() {
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.productCategoryList.length === 0) this.$store.dispatch('products/getProductCategoryList').catch(error => this.resolveError(error));
  },
  methods: {
    saveNew() {
      this.saveErrors = {};
      ProductService.addProduct(this.newProduct)
        .then(response => {
          this.saving = false;
          this.$emit('productAdded', response.data.newProduct);
          this.$store.dispatch('notifications/add', { message: 'Produkt został dodany', type: 'success' });
        })
        .catch(error => {
          this.saving = false;
          this.saveErrors = this.resolveError(error);
        });
    },
  },
};
</script>
