<template>
  <!-- <div class="flex flex-col lg:flex-row text-sm border dark:border-gray-600 border-gray-400 bg-blue-200 dark:bg-transparent px-2 py-1 rounded shadow"> -->
  <div class="flex flex-col lg:flex-row text-sm border dark:border-gray-600 border-blue-400 bg-blue-200 dark:bg-transparent px-2 py-1 rounded shadow-xl">

    <!-- Kolumna 1 - dane produktu -->
    <div class="flex-grow min-w-0 flex flex-col gap-y-2 lg:pr-2">

      <!-- wiersz 1 -->
      <div class="flex flex-wrap xl:flex-nowrap gap-y-1">

        <div class="order-1 flex-grow min-w-0 xl:w-1/5 xl:flex-grow-0 flex-shrink-0 flex flex-wrap items-center">
          <div class="w-2/5 sm:w-full lg:w-2/5 flex-shrink-0 text-bright">{{ product.insurer_name }}</div>
          <div class="w-3/5 sm:w-full lg:w-3/5 flex-shrink-0 text-bright text-right sm:text-left">{{ product.category_name }}</div>
        </div>

        <div class="order-3 xl:order-2 w-full xl:w-auto xl:flex-grow min-w-0 flex flex-wrap gap-x-3 gap-y-1 xl:flex-nowrap">
          <div class="flex-grow min-w-0 self-center">
            <div class="w-full flex items-center">
              <label
                class="flex items-center gap-1 px-1 py-1 bg-gray-100 dark:bg-transparent border border-r-0 border-gray-400 dark:border-gray-600"
              >nazwa</label>
              <input v-if="edited.name"
                @keydown.enter="save"
                @keydown.esc="cancel"
                @blur="cancel"
                type="text"
                class="w-full"
                :class="saveErrors.name != undefined ? 'bg-red-100 dark:bg-red-800': 'bg-yellow-100 dark:bg-yellow-900'"
                v-model="productCache.name"
                ref="name"
              >
              <span v-else
                @click.stop="edit('name')"
                :title="product.name"
                class="w-full px-2 py-1 border dark:border-gray-600 border-gray-400 dark:bg-gray-800 bg-white rounded-sm text-bright"
              >
                {{ product.name }}
              </span>
            </div>
            <p v-if="saveErrors.name != undefined" class="text-sm text-red-600 dark:text-red-800">{{ saveErrors.name }}</p>
          </div>

          <div class="order-5 xl:order-3 w-full sm:w-44 w-shrink-0 self-center">
            <div class="flex items-center">
              <label
                class="flex-center px-1 py-1 bg-blue-600 dark:bg-transparent border border-r-0 border-blue-400 dark:border-gray-600 text-white dark:text-gray-400"
              >{{ provisionLevels[0].name }}</label>
              <input v-if="edited.income"
                class="w-full text-right"
                :class="saveErrors.income != undefined ? 'bg-red-100 dark:bg-red-800': 'bg-yellow-100 dark:bg-yellow-900'"
                @keydown.enter="save"
                @keydown.esc="cancel"
                @blur="cancel"
                type="text"
                v-model="productCache.income"
                ref="income"
              >
              <div v-else
                @click.stop="edit('income')"
                class="w-full px-2 py-1 border border-r-0 dark:border-gray-600 border-blue-400 text-right"
                :class="product.income === '' ? 'bg-red-100 dark:bg-red-800' : 'dark:bg-gray-800  bg-blue-100' "
              >{{ product.income | currencyNullAllow }}</div>
              <span class="flex-center pr-1 py-1 bg-blue-100 dark:bg-transparent border border-l-0 border-blue-400 dark:border-gray-600 dark:bg-gray-800">%</span>
            </div>
            <p v-if="saveErrors.income != undefined" class="text-sm text-red-600 dark:text-red-800">{{ saveErrors.income }}</p>
          </div>
        </div>

        <div class="order-2 xl:order-3 flex-shrink flex">
          <div class="w-64 flex-shrink-0 flex flex-wrap justify-center items-center px-2">
            <div class="w-1/2 text-center flex gap-x-1 items-center justify-center" title="data obowiązywania produktu">
              <FontAwesomeIcon :icon="['far', 'calendar-alt']" class="text-muted" /><span class="text-muted italic">od</span>
              <span :class="product.date_from_h ? 'font-bold' : 'text-muted italic'">{{ product.date_from_h ? product.date_from_h : 'utworzenia' }}</span>
            </div>
            <div class="w-1/2 text-center flex gap-x-1 items-center justify-center" title="data obowiązywania produktu">
              <FontAwesomeIcon :icon="['far', 'calendar-alt']" class="text-muted" /><span class="text-muted italic">do</span>
              <span :class="{'text-muted italic': !product.date_to_h}">{{ product.date_to_h ? product.date_to_h : ' - aktualny' }}</span>
            </div>
          </div>
          <div class="w-10 flex-shrink-0 self-center text-center">
            <FontAwesomeIcon
              :icon="isActive ? ['far', 'check-square'] : ['fas', 'ban']"
              :title="isActive ? 'produkt aktywny' : 'produkt nieaktywny'"
              :class="{'text-red-600 dark:text-red-800': !isActive, 'text-green-500 dark:text-green-600': isActive}"
              size="lg"
            />
          </div>
        </div>


      </div><!-- wiersz 1 -->

      <!-- wiersz 2 - poziomy prowizyjne -->
      <div v-if="provisionSystem === 1" class="flex flex-wrap gap-1">
        <div class="w-24 self-center text-muted font-bold">Poziomy pr.:</div>
        <div class="flex flex-wrap gap-x-5 gap-y-1">
          <div v-for="(provision, index) in provisionLevelsForTable" :key="index" class="flex flex-wrap gap-1">
            <div class="flex flex-col gap-y-0.5">

              <div class="flex flex-wrap">

                <div class="w-full lg:w-auto lg:flex-grow lg:min-w-0 self-center mr-1">{{ provision.name }}</div>

                <!-- dwa formularze prowizji -->
                <div class="w-48 flex flex-nowrap">
                  <div class="w-1/2 flex items-center">
                    <label
                      class="flex-center px-1 py-1 dark:bg-transparent border border-r-0 dark:border-gray-600 text-white dark:text-gray-400"
                      :class="index%2 === 0 ? 'bg-green-600 border-green-400' : 'bg-indigo-600 border-indigo-400'"
                    >A</label>
                    <input v-if="edited[provisionFormNames[index].a]"
                      class="w-full text-right"
                      :class="saveErrors[provisionFormNames[index].a] != undefined ? 'bg-red-100 dark:bg-red-800': 'bg-yellow-100 dark:bg-yellow-900'"
                      @keydown.enter="save"
                      @keydown.esc="cancel"
                      @blur="cancel"
                      type="text"
                      v-model="productCache[provisionFormNames[index].a]"
                      :ref="provisionFormNames[index].a"
                    >
                    <div v-else
                      @click.stop="edit(provisionFormNames[index].a)"
                      class="w-full px-2 py-1 border border-r-0 dark:border-gray-600 text-right"
                      :class="{
                        'bg-red-100 dark:bg-red-800': product[provisionFormNames[index].a] === '',
                        'dark:bg-gray-800 bg-green-100 border-green-400': product[provisionFormNames[index].a] !== '' && index%2 === 0,
                        'dark:bg-gray-800 bg-indigo-100 border-indigo-400': product[provisionFormNames[index].a] !== '' && index%2 === 1,
                      }"
                    >{{ product[provisionFormNames[index].a] | currencyNullAllow }}</div>
                    <span
                      class="flex-center pr-1 py-1 bg-white dark:bg-transparent border border-l-0 dark:border-gray-600 dark:bg-gray-800"
                      :class="index%2 === 0 ? 'bg-green-100 border-green-400' : 'bg-indigo-100 border-indigo-400'"
                    >%</span>
                  </div>
                  <div class="w-1/2 flex items-center">
                    <label
                      class="flex-center px-1 py-1 dark:bg-transparent border border-r-0 border-l-0 dark:border-gray-600 text-white dark:text-gray-400"
                      :class="index%2 === 0 ? 'bg-green-600 border-green-400' : 'bg-indigo-600 border-indigo-400'"
                    >B</label>
                    <input v-if="edited[provisionFormNames[index].b]"
                      class="w-full text-right"
                      :class="saveErrors[provisionFormNames[index].b] != undefined ? 'bg-red-100 dark:bg-red-800': 'bg-yellow-100 dark:bg-yellow-900'"
                      @keydown.enter="save"
                      @keydown.esc="cancel"
                      @blur="cancel"
                      type="text"
                      v-model="productCache[provisionFormNames[index].b]"
                      :ref="provisionFormNames[index].b"
                    >
                    <div v-else
                      @click.stop="edit(provisionFormNames[index].b)"
                      class="w-full px-2 py-1 border border-r-0 dark:border-gray-600 text-right"
                      :class="{
                        'bg-red-100 dark:bg-red-800': product[provisionFormNames[index].b] === '',
                        'dark:bg-gray-800 bg-green-100 border-green-400': product[provisionFormNames[index].b] !== '' && index%2 === 0,
                        'dark:bg-gray-800 bg-indigo-100 border-indigo-400': product[provisionFormNames[index].b] !== '' && index%2 === 1,
                      }"
                    >{{ product[provisionFormNames[index].b] | currencyNullAllow }}</div>
                    <span
                      class="flex-center pr-1 py-1 bg-white dark:bg-transparent border border-l-0 dark:border-gray-600 dark:bg-gray-800"
                      :class="index%2 === 0 ? 'bg-green-100 border-green-400' : 'bg-indigo-100 border-indigo-400'"
                    >%</span>
                  </div>
                </div> <!-- dwa formularze prowizji -->

              </div>
              <p v-if="saveErrors[provisionFormNames[index].b] != undefined" class="text-sm text-red-600 dark:text-red-800">{{ saveErrors[provisionFormNames[index].b] }}</p>
              <p v-if="saveErrors[provisionFormNames[index].a] != undefined" class="text-sm text-red-600 dark:text-red-800">{{ saveErrors[provisionFormNames[index].a] }}</p>
            </div>
          </div>
        </div>
      </div> <!-- wiersz 2 - poziomy prowizyjne -->

    </div> <!-- Kolumna 1 - dane produktu | Kolumna 2 akcje -->

    <!-- Kolumna 2 - przyciski akcji -->
    <div class="order-first lg:order-last w-full lg:w-10 flex-shrink-0 flex flex-row lg:flex-col justify-end lg:justify-center items-center lg:border-l dark:border-gray-600 pl-2">
      <button @click="$emit('advancedEdit', product)" class="tw--btn-icon" title="Edycja zaawansowana"><FontAwesomeIcon :icon="['far', 'edit']" size="lg" /></button>
      <button
        @click="$emit('duplicate', product)"
        class="tw--btn-icon"
        :title="product.date_to === '' ? 'Duplikacja' : 'Produkt posiada kontynuację - brak mozliwości duplikacji.'"
        :disabled="product.date_to !== ''"
      ><FontAwesomeIcon :icon="['far', 'clone']" size="lg" /></button>
    </div> <!-- Kolumna 2 - przyciski akcji -->

  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import ProductService from '../../services/ProductService';

export default {
  name: 'ProductListItem',
  mixins: [errorHandler, confirm],
  props: {
    product: {
      type: Object,
      required: true,
    },
    levels: {
      type: Number,
      default: 1,
    },
    provisionLevels: {
      type: Array,
      required: true,
    },
    provisionSystem: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      edited: {
        name: false,
        income: false,
        provision_a: false,
        provision_b: false,
        provision_a1: false,
        provision_b1: false,
        provision_a2: false,
        provision_b2: false,
        provision_a3: false,
        provision_b3: false,
        provision_a4: false,
        provision_b4: false,
        date_from: false,
        date_to: false,
        disabled: false,
      },
      saveErrors: {},
      productCache: {},
      provisionFormNames: [
        // { a: 'income', b: '' },
        { a: 'provision_a', b: 'provision_b' },
        { a: 'provision_a1', b: 'provision_b1' },
        { a: 'provision_a2', b: 'provision_b2' },
        { a: 'provision_a3', b: 'provision_b3' },
        { a: 'provision_a4', b: 'provision_b4' },
      ],
    };
  },
  computed: {
    isActive() {
      if (this.product.disabled === '1' || this.product.disabled === 1) {
        return false;
      }
      return true;
    },
    date_from() {
      return this.product.date_from_h !== '' ? this.product.date_from_h : 'dd/mm/rrrr&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
    },
    date_to() {
      return this.product.date_to_h !== '' ? this.product.date_to_h : 'dd/mm/rrrr&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
    },
    refs() {
      return this.$refs;
    },
    provisionLevelsForTable() {
      return this.provisionLevels.slice(1);
    },
  },
  methods: {
    edit(field) {
      let node;
      console.log(this.$refs);
      console.log(field);
      if (['income', 'provision_b', 'provision_b'].includes(field)) {
        this.productCache[field] = this.product[field].replace('.', ',');
      } else {
        this.productCache[field] = this.product[field];
      }
      this.edited[field] = true;
      this.$nextTick(() => {
        if (Array.isArray(this.$refs[field])) {
          node = this.$refs[field][0];
          console.log(this.$refs[field][0]);
        } else {
          node = this.$refs[field];
        }
        // console.log(Array.isArray(this.$refs[field]));
        console.log(this.$refs[field]);
        // this.$refs[field].select();
        node.select();
        // this.$refs[field].focus();
        node.focus();
      });
      this.edited[field] = true;
    },
    cancel() {
      Object.keys(this.edited).forEach((key) => { this.edited[key] = false; });
      this.productCache = {};
      this.saveErrors = {};
    },
    save() {
      this.saveErrors = {};
      ProductService.updateProduct(this.product.id, this.productCache)
        .then(response => {
          Object.keys(this.edited).forEach((key) => { this.edited[key] = false; });
          this.productCache = {};
          this.$emit('productUpdated', response.data.product);
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
  },
};
</script>
